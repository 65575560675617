import { Button, FormControl } from "@mui/material";
import React, { useState } from "react";
import styles from "./releaseVNModal.module.css";
import CustomModal from "../ui/CustomModal/customModal";

function ReleaseVNModal(props) {
  return (
    <CustomModal
      visible={props.visible}
      closeModal={props.onClose}
      bodyStyles={{ maxHeight: "75vh", padding: "0 1em" }}
      heading="Release Virtual Numbers"
      body={
        <div className={styles.modal}>
          <p>Do you want to release the virtual numbers?</p>
          <div className={styles.action_btn}>
            <Button
              variant="outlined"
              style={{
                fontSize: 14,
                height: "max-content",
                margin: "10px",
                width: "max-content",
              }}
              sx={{
                ":hover": {
                  bgcolor: "#34cb65",
                  color: "white",
                },
              }}
              color="success"
              onClick={() => {
                props.handleReleaseVN(true);
                props.onClose();
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              style={{
                fontSize: 14,
                height: "max-content",
                margin: "10px",
                width: "max-content",
              }}
              sx={{
                ":hover": {
                  bgcolor: "#34cb65",
                  color: "white",
                },
              }}
              color="success"
              onClick={() => {
                props.handleReleaseVN(false);
                props.onClose();
              }}
            >
              No
            </Button>
          </div>
        </div>
      }
    />
  );
}

export default ReleaseVNModal;
