import React from "react";
import Button from "@mui/material/Button";

import TuneIcon from '@mui/icons-material/Tune';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import styles from './CustomFilters.module.css'
function CustomFilters({
  columns,
  onFiltersChange,
  filterOptions,
}) {
  const handleFieldChange = (index, value) => {
    const newFilterOptions = [...filterOptions];
    newFilterOptions[index].field = value;
    onFiltersChange(newFilterOptions);
  };

  const handleValueChange = (index, value) => {
    const newFilterOptions = [...filterOptions];
    newFilterOptions[index].value = value;
    onFiltersChange(newFilterOptions);
  };

  const handleRemoveFilter = (index) => {
    const newFilterOptions = [...filterOptions];
    newFilterOptions.splice(index, 1);
    onFiltersChange(newFilterOptions);
  };

  const handleAddFilter = () => {
    const newFilterOptions = [
      ...filterOptions,
      { id: Date.now(), field: "", value: "" },
    ];
    onFiltersChange(newFilterOptions);
  };

  return (
    <div className={styles.container}>
      {filterOptions.map((option, index) => (
        <div
          key={option.id}
          className={styles.inputKeys}
        >
                <select
                 className={styles.filterSelect}
                  value={option.field}
                  onChange={(e) => handleFieldChange(index, e.target.value)}
                  style={{ marginRight: "8px" }}
                >
                  <option value="" disabled >
                    Select Field
                  </option>
                  {columns.map((column) => (
                    <option key={column.field} value={column.field}>
                      {column.headerName}
                    </option>
                  ))}
                </select>
          <div  className={styles.inputstyles} >
          <input
          className={styles.filterstyles}
            type="text"
            placeholder={`Search value`}
            value={option.value}
            onChange={(e) => handleValueChange(index, e.target.value)}
          />
          <Button onClick={() => handleRemoveFilter(index)}><HighlightOffRoundedIcon
           sx={{
            color: "darkgray",
            ":hover": {
              color: "lightgray",
            },
          }}/></Button></div>
        </div>
      ))}
      <button   className={styles.FilterButton} onClick={handleAddFilter} ><TuneIcon/></button>
    </div>
  );
}

export default CustomFilters;
