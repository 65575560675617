import styles from "./details.module.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { CircularProgress } from "@mui/material";
import ApiService from "../../../../components/apiService/ApiService";
import {
  Button,
  FormControlLabel,
  Switch,
  ThemeProvider,
  FormGroup
} from "@mui/material";
import Snackbar from "../../../../components/ui/CustomSnackbar/customSnackbar";
import ReleaseVNModal from "../../../../components/releaseVNModal/releaseVNModal";
import { styled, useTheme } from "@mui/material/styles";
import CustomModal from "../../../../components/ui/CustomModal/customModal";
import { useNavigate } from "react-router";

function Details(props) {
  const theme = useTheme();
  let { accountId } = useParams();
  const [details, setDetails] = useState(null);
  const [company, setCompany] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [primaryAccount, setPrimaryAccount] = useState(null);
  const [dailyReports, setDailyReports] = useState(null);
  const [twoFactorAuth, setTwoFactorAuth] = useState(null);
  const [privateRecording, setPrivateRecording] = useState(null);
  const [recordWarning, setRecordWarning] = useState(null);
  const [updatePayload, setUpdatePayload] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [showReleaseVNModal, setShowReleaseVNModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [response, setResponse] = useState({});
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [useCase , setUseCase]= useState("");
  const [teamSize, setTeamSize] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setShowLoader(true);
    ApiService.get(`/api/v1/dashboard/retrieve-account-details/${accountId}/`)
      .then((res) => {
        handleFeedback(res);
        if (res.data.success === true) {
            setDetails(res.data.data);
            updateDetails(res.data.data);
            setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
      });
  };

  const updateDetails = (data) => {
    
    setCompany(data.company);
    setCreatedBy(data.created_by);
    setCreatedAt(data.created_at);
    setUpdatedAt(data.updated_at);
    setDailyReports(data.daily_reports);
    setPrimaryAccount(data.primary_account);
    setTwoFactorAuth(data.two_factor_auth);
    setPrivateRecording(data.private_recording);
    setRecordWarning(data.record_warning);
    setUseCase(data.use_case);
    setTeamSize(data.team_size);
  };

  const handleDiscardChanges = () => {
    updateDetails(details);
    setDisabled(true);
    setUpdatePayload({});
    setSaveButtonEnabled(false);
  };
  
  const handleSaveChanges = () => {
    ApiService.patch(
      `/api/v1/dashboard/update-account-details/${accountId}/`,
      updatePayload
    )
      .then((response) => {
        setDisabled(true);
        setDetails(response.data.data);
        updateDetails(response.data.data);
        setUpdatePayload({});
        props.handleFeedback(response);
        setSaveButtonEnabled(false);
      })
      .catch((err) => {
        console.log("Error", err);
        handleFeedback(err?.response);
      });
  };

  const handleReleaseVN = () => {
    let payload = {
      "release_vns": true
    }
    ApiService.patch(
      `/api/v1/dashboard/update-account-details/${accountId}/`,
      payload
    )
      .then((response) => {
        setDetails(response.data.data);
        updateDetails(response.data.data);
        handleFeedback(response);
        setShowReleaseVNModal(false);
      })
      .catch((err) => {
        console.log("Error", err);
        handleFeedback(err?.response);
        setShowReleaseVNModal(false);
      });
  };

  const handleFeedback = (response) => {
    if (response && response.data) {
      setResponse(response.data);
    } else if (response && response.status === 400) {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    } else {
      setResponse({
        message: "Updated successfully",
        success: true,
      });
    }
    setShowSnackbar(true);
  };
  const navigate = useNavigate();


  const handleDeleteAccount = () => {
    ApiService.delete(`/api/v1/dashboard/delete-account/${accountId}/`)
      .then((response) => {
        handleFeedback(response);
        setShowDeleteModal(false);
        navigate(`/`)

      })
      .catch((error) => {
        console.error("Error deleting account:", error);
        setShowDeleteModal(false);
      });
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <div className={styles.settings}>
        {showLoader ? (
          <div className={styles.loader_wrapper}>
            <CircularProgress color="success" size={60} />
          </div>
        ) : (
          <>
          <div className={styles.accountSettings}>
            <label className={styles.mainheading}>Account Information</label>
            <div className={styles.property}>
              <label>Company</label>
              <input
                type="text"
                name="company"
                value={company}
                onChange={(e) => {
                  setDisabled(false);
                  setCompany(e.target.value);
                  const temp = updatePayload;
                  temp["company"] = e.target.value;
                  setUpdatePayload(temp);
                }}
              />
            </div>

            <div className={styles.property}>
              <label>Created by</label>
              <input
                type="email"
                name="created_by"
                disabled
                value={createdBy}

              />
            </div>
            <div className={styles.property}>
              <label>Created At</label>
              <input
                type="text"
                name="created_at"
                value={new Date(createdAt)}
                disabled
              />
            </div>
            <div className={styles.property}>
              <label>Updated At</label>
              <input
                type="text"
                name="updated_at"
                value={new Date(updatedAt)}
                disabled
              />
            </div>
          </div>
          <div className={styles.accountSettings}>
            <label className={styles.mainheading}>Private & Security</label>
            <div className={styles.property}>
              <ThemeProvider theme={theme}>
                <FormGroup>
                  <FormControlLabel
                    value={twoFactorAuth}
                    style={{ height: "20px" }}
                    control={
                      <IOSSwitch
                        checked={twoFactorAuth}
                        onChange={(e) => {
                          setDisabled(false);
                          setTwoFactorAuth(e.target.checked);
                          const temp = updatePayload;
                          temp["two_factor_auth"] = e.target.checked;
                          setUpdatePayload(temp);
                        }}
                        sx={{ m: 1 }}
                        defaultChecked
                      />
                    }
                    label="2 factor Authentication"
                    labelPlacement="end"
                  />
                </FormGroup>
              </ThemeProvider>
            </div>
            <div className={styles.txt}>
              Sharable recording links would be private.
            </div>
            <div className={styles.property}>
              <ThemeProvider theme={theme}>
                <FormGroup>
                  <FormControlLabel
                    style={{ height: "20px" }}
                    value={privateRecording}
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        checked={privateRecording}
                        onChange={(e) => {
                          setDisabled(false);
                          setPrivateRecording(e.target.checked);
                          const temp = updatePayload;
                          temp["private_recording"] = e.target.checked;
                          setUpdatePayload(temp);
                        }}
                      />
                    }
                    label="Private Recording"
                    labelPlacement="end"
                  />
                </FormGroup>
              </ThemeProvider>
            </div>
            <div className={styles.txt}>
              Make 2FA as default security verification method for all users.
            </div>
            <div className={styles.property}>
              <ThemeProvider theme={theme}>
                <FormGroup>
                  <FormControlLabel
                    value={recordWarning}
                    style={{ height: "20px" }}
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        checked={recordWarning}
                        onChange={(e) => {
                          setDisabled(false);
                          setRecordWarning(e.target.checked);
                          const temp = updatePayload;
                          temp["record_warning"] = e.target.checked;
                          setUpdatePayload(temp);
                        }}
                      />
                    }
                    label="Record Warning"
                    labelPlacement="end"
                  />
                </FormGroup>
              </ThemeProvider>
            </div>
            <div className={styles.txt}>
              Notify both the caller and recipient that the call is being
              recorded.
            </div>
            <br />
            <label className={styles.mainheading}>Reporting</label>
            <div className={styles.property}>
              <ThemeProvider theme={theme}>
                <FormGroup>
                  <FormControlLabel
                    value={dailyReports}
                    style={{ height: "20px" }}
                    control={
                      <IOSSwitch
                        checked={dailyReports}
                        onChange={(e) => {
                          setDisabled(false);
                          setDailyReports(e.target.checked);
                          const temp = updatePayload;
                          temp["daily_reports"] = e.target.checked;
                          setUpdatePayload(temp);
                        }}
                        sx={{ m: 1 }}
                        defaultChecked
                      />
                    }
                    label="Daily Reports"
                    labelPlacement="end"
                  />
                </FormGroup>
              </ThemeProvider>
            </div>
            <div className={styles.txt}>
              Get daily call reports through e-mail.
            </div>
          </div>
          <div className={styles.accountSettings}>
            <label className={styles.mainheading}> Settings</label>
            <div className={styles.property}>
              <ThemeProvider theme={theme}>
                <FormGroup>
                  <FormControlLabel
                    style={{ height: "20px" }}
                    value={primaryAccount}
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        checked={primaryAccount}
                      />
                    }
                    label="Primary Account"
                    labelPlacement="end"
                  />
                </FormGroup>
              </ThemeProvider>
            </div>
            <div className={styles.txt}>
              Selected account will be primary account
            </div>
            <div className={styles.property}>
              <label>Use Case</label>
              <input
                  type="text"
                  name="use_case"
                  value={useCase}
               onChange={(e)=>{
                setDisabled(false)
                setUseCase(e.target.value)
                const temp = updatePayload
                temp["use_case"]=e.target.value
                setUpdatePayload(
                  temp
                )
               }}
                />
            </div>
            <div className={styles.property}>
              <label>Team size</label>
              <input
                  type="text"
                  name="team_size"
                  value={teamSize}
                />
            </div>
          </div>

          <div className={styles.accountSettings}>
            <Button
              variant="contained"
              style={{
                fontSize: 14,
                height: "max-content",
                marginTop: "10px",
                width: "97px",
              }}
              sx={{
                bgcolor: "#E0544C",
                ":hover": {
                  bgcolor: "indianred",
                  color: "white",
                },
              }}
              onClick={()=>setShowDeleteModal(true)}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              style={{
                fontSize: 14,
                height: "max-content",
                marginTop: "10px",
                width: "max-content",
                color: "#464D48",
              }}
              sx={{
                bgcolor: "#E0F2FE",
                ":hover": {
                  bgcolor: "#E0F2FE",
                  color: "#464D48",
                },
              }}
              onClick={()=>setShowReleaseVNModal(true)}
            >
              Release VNs
            </Button>
            <div className={styles.updateButtons}>
              <Button
                  onClick={handleDiscardChanges}
                  disabled={disabled}
                  color="success"
                  variant="outlined"
                  size="medium"
                  style={{
                    fontSize: 14,
                    height: "max-content",
                    marginTop: "10px",
                    width: "max-content",
                  }}
                >
                  Cancel
              </Button>
              <Button
                label="Save"
                onClick={handleSaveChanges}
                color="success"
                variant="contained"
                disabled={disabled}
                style={{
                  fontSize: 14,
                  height: "max-content",
                  marginTop: "10px",
                  width: "max-content",
                }}
                sx={{
                  bgcolor: "#34cb65",
                  ":hover": {
                    bgcolor: "lightgreen",
                    color: "white",
                  },
                }}
              >
                Save
              </Button>
            </div>
            <CustomModal
            visible={showDeleteModal}
            heading={"Delete Account"}
            closeModal={()=>setShowDeleteModal(false)}
            bodyStyles={{
              height: "170px",
              width: "350px",
              padding: "1em",
            }}
            body={ 
              <div className={styles.startTrial}>
                <div className={styles.startTrialtxt}>  Are you sure you want to delete the account ?</div>
                <div>
                  <Button
                    variant="contained"
                    style={{
                      fontSize: 14,
                      height: "max-content",
                      marginTop: "10px",
                      width: "97px",
                    }}
                    sx={{
                      bgcolor: "#E0544C",
                      ":hover": {
                        bgcolor: "indianred",
                        color: "white",
                      },
                    }}
                    onClick={handleDeleteAccount}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            }
            />
            <CustomModal
            visible={showReleaseVNModal}
            heading={"Release virtual numbers"}
            closeModal={()=>setShowReleaseVNModal(false)}
            bodyStyles={{
              height: "170px",
              width: "350px",
              padding: "1em",
            }}
            body={ 
              <div className={styles.startTrial}>
                <div className={styles.startTrialtxt}>  Are you sure you want to release the virtual numbers ?</div>
                <div>
                  <Button
                    variant="contained"
                    style={{
                      fontSize: 14,
                      height: "max-content",
                      marginTop: "10px",
                      width: "max-content",
                      color: "#464D48",
                    }}
                    sx={{
                      bgcolor: "#E0F2FE",
                      ":hover": {
                        bgcolor: "#E0F2FE",
                        color: "#464D48",
                      },
                    }}
                    onClick={handleReleaseVN}
                  >
                    Release
                  </Button>
                </div>
              </div>
            }
            />
          </div>
          </>
        )}
        {showSnackbar && (
          <Snackbar
            message={typeof response.message === 'object' ? response.message.detail : response.message}
            type={response.success ? 'success' : 'error'}
            onClose={() => setShowSnackbar(false)}
          />
        )}
    </div>
  );
}

export default Details;
