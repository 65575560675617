import { Grid } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import styles from "./usage.module.css";
import Snackbar from "../../../../components/ui/CustomSnackbar/customSnackbar";
import CustomTable from "../../../../components/ui/CustomTable/customTable";
import ApiService from "../../../../components/apiService/ApiService";
import CircularProgress from "@mui/material/CircularProgress";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Button } from "@mui/material"
import { Download as DownloadIcon} from "@mui/icons-material";
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
function Usage(props) {
  let { accountId } = useParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [response, setResponse] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [stats, setStats] = useState({ total: 0, answered: 0 });
  const [userWiseData, setUserWiseData] = useState();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Call pick up rate (Date wise)",
      },
    },
  };


  useEffect(() => {
    const defaultEndDate = new Date().toISOString().split("T")[0];
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const defaultStartDate = oneWeekAgo.toISOString().split("T")[0];
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    getStatsHandler()
  }, []);

  useEffect(() => {
    getStatsHandler();
  }, [endDate]);
  console.log(endDate);
  function formatTableData(data) {
    for (let i = 0; i < data.length; i++) {
      data[i].id = data[i].creator;
      data[i].call_pickup_rate =
        Number(data[i].total_calls) > 0
          ? `${(
              (Number(data[i].answered) / Number(data[i].total_calls)) *
              100
            ).toFixed(2)}%`
          : "N/A";
    }
    setUserWiseData(data);
  }
  const columns = [
    {
      field: "creator",
      headerName: "Creator",
      flex: 1,
    },

    {
      field: "total_calls",
      headerName: "Total Calls",
      flex: 1,
    },
    {
      field: "answered",
      headerName: "Answered",
      flex: 1,
    },
    {
      field: "not_answered",
      headerName: "Unanswered",
      flex: 1,
    },
    {
      field: "call_pickup_rate",
      headerName: "Call Pickup Rate",
      flex: 1,
    },
  ];
  const handleFeedback = (response) => {
    if (response) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };
  const getStatsHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (
      startDate > endDate ||
      new Date(startDate) > new Date() ||
      new Date(endDate) > new Date()
    ) {
      setChartData({ datasets: [] });
      setStats({ total: 0, answered: 0 });
      setUserWiseData();
      setResponse(null);
      handleFeedback({
        data: { success: false, message: "Date range selected is invalid." },
      });
      return;
    }
    if (accountId && accountId < 0) {
      setChartData({ datasets: [] });
      setStats({ total: 0, answered: 0 });
      setUserWiseData();
      setResponse(null);
      handleFeedback({
        data: { success: false, message: "Account id is invalid." },
      });
      return;
    }
    if (
      !accountId ||
      accountId.trim().length === 0 ||
      !startDate ||
      !endDate ||
      startDate > endDate
    ) {
      setChartData({ datasets: [] });
      setStats({ total: 0, answered: 0 });
      setUserWiseData();
      setResponse(null);
      return;
    }
    setShowLoader(true);
    ApiService.get(
      `/api/v1/dashboard/call-analytics/${accountId}/?date_start=${startDate}&date_end=${endDate}`,
    )
      .then((res) => {
        setShowLoader(false);
        handleFeedback(res);
        if (res.data.success === true) {
          const {
            data: { date_wise_data: data, user_wise_data },
          } = res;
          formatTableData(user_wise_data);
          const labels = data?.map((elem) => elem.date);
          setStats(
            data?.reduce(
              (acc, cur) => {
                acc.total += cur.total_calls;
                acc.answered += cur.answered;
                return acc;
              },
              { answered: 0, total: 0 },
            ),
          );
          setChartData({
            labels,
            datasets: [
              {
                label: "Total calls",
                data: data?.map((elem) => elem.total_calls),
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
              {
                label: "Answered",
                data: data?.map((elem) => elem.answered),
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
              {
                label: "Unanswered",
                data: data?.map((elem) => elem.not_answered),
                borderColor: "rgb(40,40,40)",
                backgroundColor: "rgba(40,40,40,0.5)",
              },
            ],
          });
        }
      })
      .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
        console.log("err", err);
      });
  };

  const handleDownloadCallLogReport = async () => {
    setShowLoader(true);
    try{
      const res = await ApiService.get(`/api/v1/dashboard/download-report/${accountId}/?date_start=${startDate}&date_end=${endDate}&report=call-log`);
      if(res.data.success){
        handleFeedback(res);
      }
      else{
        throw new Error(res.data.message || 'Error while generating credit usage report');
      }
    }
    catch(err){
      handleFeedback(err.response);
    }
    finally{
      setShowLoader(false);
    }
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.main}>
          {!showLoader && (
            <form className={styles.form}>
              <div>
                <br />
                <input
                  style={{ display: "none" }}
                  type="text"
                  id="accountId"
                  className={styles.input}
                  value={accountId}
                  placeholder="Enter Account ID"
                />
              </div>
              <div className={styles.dateInputs}>
              <Grid container sx={{ justifyContent: "flex-end" }} spacing={2}>
                <Grid item>
                  <label htmlFor="dateRange">Date Range:</label>
                  <br />
                  <LocalizationProvider  dateAdapter={AdapterDayjs}>
                    <DateRangePicker 
                    slots={{ field: SingleInputDateRangeField }}
                      value={[dayjs(startDate), dayjs(endDate)]}
                      onChange={(newValue) => {
                        setStartDate(newValue[0].format("YYYY-MM-DD"));
                        setEndDate(newValue[1].format("YYYY-MM-DD"));
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>      
            <Button 
              variant="outlined"
              sx={{
                bgcolor: "#36cb64",
                color: "white",
                height:'52px',
                borderTopLeftRadius:'0px',
                borderBottomLeftRadius:'0px',
                marginTop:'25px',
                ":hover": {
                  bgcolor: "#28994B",
                },
              }}
              color="success"
              onClick={getStatsHandler}>
              GetData
            </Button>
            <Button
              size="small"
              sx={{
                bgcolor: "#34cb65",
                marginLeft: "5px",
                height: '52px',
                width: '220px',
                fontSize: '14px',
                gap: '6px',
                maxWidth: 'max-content',
                ":hover": {
                  bgcolor: "#28994B",
                  color: "white",
                },
              }}
              variant="contained"
              disabled = {!startDate || !endDate}
              onClick = {handleDownloadCallLogReport}
            > Call log report
              <DownloadIcon />
            </Button>
              </div>
            </form>
          )}
          {showLoader && (
            <div className={styles.loader_wrapper}>
              <CircularProgress color="success" size={60} />
            </div>
          )}
          {!showLoader && !response && (
            <p className={styles.default_para}>
              Enter the date range to see the usage statistics.
            </p>
          )}
          {!showLoader && (
            <>
              <Line options={options} data={chartData} />
            </>
          )}
        </div>
        <div className={styles.main}>
          {!showLoader && response && chartData && (
            <div>
              <Grid
                container
                sx={{ justifyContent: "space-between", marginTop: "30px" }}
              >
                <Grid item className={styles.stats_container}>
                  <h4 className={styles.stats_heading}>Total Calls</h4>
                  <p className={styles.stats_val}>{stats.total}</p>
                </Grid>
                <Grid item className={styles.stats_container}>
                  <h4 className={styles.stats_heading}>Answered Calls</h4>
                  <p className={styles.stats_val}>{stats.answered}</p>
                </Grid>
                <Grid item className={styles.stats_container}>
                  <h4 className={styles.stats_heading}>Unanswered Calls</h4>
                  <p className={styles.stats_val}>
                    {stats.total - stats.answered}
                  </p>
                </Grid>
                <Grid item className={styles.stats_container}>
                  <h4 className={styles.stats_heading}>Call Pickup Rate</h4>
                  <p className={styles.stats_val}>
                    {stats.total > 0
                      ? `${((stats.answered / stats.total) * 100).toFixed(2)}%`
                      : "N/A"}
                  </p>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
        {showSnackbar && (
          <Snackbar
            setShowSnackbar={setShowSnackbar}
            message={response.message}
            onClose={() => setShowSnackbar(false)}
            type={response.success ? "success" : "error"}
          />
        )}
      </div>
      <div style={{ width: "100%", margin: "40px auto" }}>
        {!showLoader && userWiseData && (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <h2 className={styles.h2}>Call Pickup Rate (user-wise)</h2>
            <CustomTable
              isTextFieldDisabled={true}
              rows={userWiseData}
              columns={columns}
              pagination={"local"}
              //filterValue={filterValue}
              disableCheckboxSelection={true}
              noRowsOverlayText="Usage"
              export={true}
              showFilters={false}
            />
          </div>
        )}
      </div>
    </>
  );
}
export default Usage;