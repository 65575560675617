import React, { useState } from "react";
import { useParams } from "react-router";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import commonStyles from "../../../components/ui/CommonStyles.module.css";
import styles from "./accountDetails.module.css";
import Details from "./Details/details";
import BillingDetails from "./Billing/billingDetails";
import UserList from "./Users/users";
import Usage from "./Usage/usage";

function AccountDetails() {
  let { accountId } = useParams();
  const [showDetails, setShowDetails] = useState(true);
  const [showBilling, setShowBilling] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showUsage, setShowUsage] = useState(false);

  return (
    <div className={`${commonStyles.page_container} ${styles.container} body_gap`}>
      <Accordion expanded={showDetails} onChange={(e, isExpanded) => setShowDetails(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Account Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {showDetails && <Details />}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={showBilling} onChange={(e, isExpanded) => setShowBilling(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Billing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {showBilling && <BillingDetails />}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={showUsers} onChange={(e, isExpanded) => setShowUsers(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Users</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {showUsers && <UserList />}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={showUsage} onChange={(e, isExpanded) => setShowUsage(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Usage</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {showUsage && <Usage accountId={accountId} />}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AccountDetails;
