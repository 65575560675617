import React from "react";
import Modal from "@mui/material/Modal";
import styles from "./customModal.module.css";
import { Cancel } from "@mui/icons-material";
import { Button } from "@mui/material";

export default function CustomModal({
  visible,
  body,
  disableCloseBtn,
  closeModal,
  heading,
  headingBtn,
  bodyStyles,
  hideHeading, 
  hideForeground, 
}) {
  return (
    <div>
      <Modal
        open={visible}
        onClose={() => {}}
        className={styles.modal_background}
      >
        {!hideForeground && (
          <div className={styles.modal_foreground}>
            {!hideHeading && (
              <div className={styles.modal_heading}>
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <h3>{heading}</h3>
                {headingBtn}
                </div>
                
                <Cancel
                  className={styles.close_btn}
                  onClick={() => {
                    if (!disableCloseBtn) closeModal();
                  }}
                  disabled={disableCloseBtn}
                />
              </div>
            )}

            {/* Modal Body */}
            <div className={styles.modal_body} style={bodyStyles}>
              {body}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
