import { useState, useEffect } from "react";
import { CircularProgress, Breadcrumbs, Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import CustomTable from "../../components/ui/CustomTable/customTable";
import Snackbar from "../../components/ui/CustomSnackbar/customSnackbar";
import pageStyles from "../../components/ui/CommonStyles.module.css";
import ApiService from "../../components/apiService/ApiService";
import styles from "./iamUsers.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/CloseOutlined";
import CustomModal from "../../components/ui/CustomModal/customModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function IAMUsers() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [response, setResponse] = useState({});
  const [newUser, setNewUser] = useState("");
  const [email, setEmail] = useState("");
  const [userPermissions, setUserPermissions] = useState([]);
  const [addPermissions, setAddPermissions] = useState([]);
  const [groupPermissions, setGroupPermissions] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true); 

  const handleFeedback = (response) => {
    if (response && response.data) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  const fetchData = () => {
    setShowLoader(true);
    ApiService.get(`/api/v1/dashboard/retrieve-iam-users/`)
      .then((res) => {
        handleFeedback(res);
        if (res.data.success === true) {
          setUsers(res.data.data);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
      });
  };
  const fetchPermissions = () => {
    ApiService.get(`/api/v1/dashboard/iam-permissions/`)
      .then((res) => {
        if (res.data.success === true) {
          setAddPermissions(res.data.data);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
      });
  };


  const handleDeletePermission = (permission, userEmail) => {
    ApiService.delete(`/api/v1/dashboard/iam-users/?user_email=${userEmail}`)
      .then((res) => {
        setShowModal(false);
        fetchData();
        if (res.data.success === true) {
          setUserPermissions((prevPermissions) =>
            prevPermissions.filter((p) => p !== permission)
          );
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };
  const handleAddPermission = (permission) => {
    const isPermissionInGroup = groupPermissions.includes(permission);
    const isPermissionExists = userPermissions.includes(permission);
    if (!isPermissionExists && !isPermissionInGroup) {
      setUserPermissions(prevPermissions => [...prevPermissions, permission]);
      setSaveDisabled(false);
    }
  };
  
  

  const handleDissociatePermissions = (permission) => {
    const updatedPermissions = userPermissions.filter(
      (perm) => perm !== permission
    );
    setUserPermissions(updatedPermissions);
    setSaveDisabled(false); 
  };
  
  const handleSaveChanges = () => {
    setShowLoader(true);
    ApiService.patch(`/api/v1/dashboard/iam-users/`, {
      user_email: selectedUser.row.email,
      permissions: userPermissions  
    })
      .then((res) => {
          setShowLoader(false)
          setSaveDisabled(true); 
          fetchData();
          handleFeedback(res);
      })
      .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (showModal) {
      fetchPermissions();
    }
  }, [showModal]);

  const columns = [{ headerName: "Email", field: "email", minWidth: 150, flex: 1 }];

  const handleRowClick = (selectedUser) => {
    setSelectedUser(selectedUser);
    setShowModal(true);
    setEmail(selectedUser.row.email);
    setUserPermissions(selectedUser.row.user_permissions);
    setGroupPermissions(selectedUser.row.group_permissions);
  };
  const handleAddUser = () => {
    setShowLoader(true);
    ApiService.post(`/api/v1/dashboard/iam-users/`, {
      user_email: newUser,
    })
    .then((res) => {
      setShowLoader(false);
      handleFeedback(res);
      if (res.data.success === true) {
        setShowAddModal(false);
        setNewUser(""); 
        fetchData();
      }
    })
    .catch((err) => {
      setShowLoader(false);
      handleFeedback(err.response);
    });
  };
  
  
  return (
    <div className={`${pageStyles.page_container} body_gap`}>
      <div className={styles.page_title}>
        <Breadcrumbs className={pageStyles.breadcrumbs} aria-label="breadcrumb">
          <p>IAM Users ({users.length})</p>
        </Breadcrumbs>
      </div>
      <Accordion
        expanded={showAddModal}
        onChange={(e, isExpanded) => setShowAddModal(isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: "#F0FCF4" }}
        >
          <Typography sx={{ color: "#34cb65" }}>Add IAM user</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px" }}>
          <form>
            <div className={styles.container}>
              <div className={styles.property}>
                <label>Email</label>
                <input
                  type="text"
                  name="user_email"
                  value={newUser}
                  onChange={(e) => setNewUser(e.target.value)}
                />
              </div>
              <div className={styles.property}>
                <Button
                  sx={{
                    bgcolor: "#34cb65",
                    marginTop: "38px",
                    ":hover": {
                      bgcolor: "#34cb65",
                      color: "white",
                    },
                  }}
                  variant="contained"
                  label="Add"
                  onClick={handleAddUser}
                >
                  Add
                </Button>
              </div>
            </div>
          </form>
        </AccordionDetails>
      </Accordion>
      {showLoader ? (
        <div className={styles.loader_wrapper}>
          <CircularProgress color="success" size={60} />
        </div>
      ) : (
        <CustomTable
          noRowsOverlayText="IAM users"
          isTextFieldDisabled={true}
          columns={columns}
          rows={users}
          rowHeight={80}
          onCellClick={handleRowClick}
          pageSize={10}
          exportable={false}
          disableCheckboxSelection={true}
          filterable={false}
        />
      )}
      {showModal && (
        <CustomModal
          visible={showModal}
          heading={"IAM User"}
          closeModal={() => {
            setShowModal(false);
            setSaveDisabled(true)
          } }
          bodyStyles={{
            height: "570px",
            width: "520px",
            padding: "1em",
          }}
          body={
            <div className={styles.modalcontainer}>
              <div className={styles.header}>User Email</div>
              <div className={styles.input}>
                <input value={selectedUser.row.email} readOnly type="text" />
              </div>
              Permissions
              <div className={styles.input}>
                <select
                  id="permissions"
                  onChange={(e) => {
                    handleAddPermission(e.target.value);
                  }}
                      >
                 <option value="" disabled selected>Select Permission</option>
                  {addPermissions.map((permission) => (
                    <option
                      key={permission.id}
                      value={permission}
                    >
                      {permission}
                    </option>
                  ))}
                </select>
              </div>
              <br/>
              <div
                style={{
                  height: "313px",
                  width: "437px",
                  border: "1px solid #E1E5E2",
                  borderRadius: "4px",
                }}
              >
                  {groupPermissions.map((groupPermission, index) => (
                    <Chip
                      label={groupPermission}
                      variant="filled"
                      sx={{
                        margin: "5px",
                        borderRadius: "4px",
                        height: "26px",
                      }}
                    />
                  ))}
                  {userPermissions.map((permission, index) => (
                    <Chip
                      key={index}
                      label={permission}
                      deleteIcon={<CancelIcon style={{ fontSize: "22px" }} />}
                      onDelete={() => handleDissociatePermissions(permission)}

                      variant="outlined"
                      sx={{
                        margin: "5px",
                        borderRadius: "4px",
                        height: "26px",
                      }}
                    />
                  ))}
              </div>

              <div className={styles.updateButtons}>
              <Button
                  size="medium"
                  style={{
                    fontSize: 14,
                    height: "max-content",
                    marginTop: "10px",
                    width: "max-content",
                    color:'white',
                    backgroundColor:'indianred'
                  }}
                  onClick={() =>
                    handleDeletePermission(
                      selectedUser.row.user_permissions,
                      selectedUser.row.email
                    )}
                >
                  Delete
              </Button>
              <Button
                label="Save"
                onClick={handleSaveChanges}
                color="success"
                variant="contained"
                disabled={saveDisabled}
                style={{
                  fontSize: 14,
                  height: "max-content",
                  marginTop: "10px",
                  width: "max-content",
                }}
                sx={{
                  bgcolor: "#34cb65",
                  ":hover": {
                    bgcolor: "lightgreen",
                    color: "white",
                  },
                }}
              >
                Save
              </Button>
            </div>
            </div>
          }
        />
      )}

      {showSnackbar && (
        <Snackbar
          message={response.message}
          type={response.success ? "success" : "error"}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
}

export default IAMUsers;
