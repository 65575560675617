import React, { useState, useEffect } from 'react';
import './Snackbar.css';

const Snackbar = ({ message, onClose, type }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (typeof onClose === 'function') {
        onClose();
      }
    }, 3000);
  
    return () => clearTimeout(timer);
  }, [onClose]);

  const snackbarClassName = `snackbar ${visible ? 'visible' : ''} ${type === 'error' ? 'error' : ''} ${type === 'success' ? 'success' : ''}`;

  return (
    <div className={snackbarClassName} >
      <span className="message">{message}</span>
      <button className="closeButton" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default Snackbar;