import axios from "axios";
import { useHistory } from 'react-router-dom';
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: 'http://127.0.0.1:8000/'
    withCredentials: true,
});


instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const response = await axios.post('api/v1/auth/token/refresh/?webapp=true', null, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        });
        const { access } = response.data;
        originalRequest.headers.Authorization = `Bearer ${access}`;
      return instance(originalRequest); 
      } 
      catch (refreshError) {
        localStorage.removeItem('isAuthenticated');
        window.location.href = '/login'; 
        return Promise.reject(refreshError);
    }
    }

    return Promise.reject(error);
  }
);

const ApiService = {
  get(url, params = {}) {
    return instance
      .get(url, { params: params })
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },

  post(url, data) {
    return instance
      .post(url, data)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },

  patch(url, data) {
    return instance
      .patch(url, data)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },
  put(url, data) {
    return instance
      .put(url, data)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },
  delete(url, data = {}) {
    return instance
      .delete(url, { data: data })
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },

  awaitAll() {
    return axios
      .all(Array.from(arguments))
      .then(axios.spread((...responses) => responses))
      .catch((reasons) => Promise.reject(reasons));
  },
};

export default ApiService;