import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import "./Login.css";
import Snackbar from "../../components/ui/CustomSnackbar/customSnackbar"
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [response, setResponse] = useState({});
  const navigate = useNavigate();

  const handleFeedback = (response) => {
    if (response) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  const loginHandler = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };
    axios
      .post("api/v1/dashboard/login/", data,{withCredentials : true})
      .then((response) => {
        handleFeedback(response);
        if (response.data.success) {
        localStorage.setItem("isAuthenticated", true);
          navigate("/");
        }
      })
      .catch((error) => {
        handleFeedback(error.response);
        console.log(error.response);
      });
  };

  return (
    <div className="login-page-container">
      {showSnackbar && (
        <Snackbar message={response.message}
        type={response.success ? 'success' : 'error'}
        onClose={() => setShowSnackbar(false)} />)}
        
        <div className="login-container">
          <div className="login-header">Dashboard Login</div>
          <div className="form">
            <form className="login-form" onSubmit={loginHandler}>
              <div className="headings">Email</div>
              <input
                type="text"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="headings">Password</div>
              <input
                type="password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button variant="contained" type="submit">
                Login
              </Button>
            </form>
          </div>
        </div>
    </div>
  );
}
