import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../../../components/apiService/ApiService";
import styles from "./users.module.css";
import CustomSnackbar from "../../../../components/ui/CustomSnackbar/customSnackbar";
import CustomTable from "../../../../components/ui/CustomTable/customTable";
import UserDetails from "./UserDetails/userDetails";

function formatTableData(users) {
  for (let i = 0; i < users.length; i++) {
    users[i]["is_active"] = users[i]["is_active"].toString();
    users[i]["role"] = users[i]["profile"]["role"];
    users[i]["account_holder"] = users[i]["profile"]["account_holder"].toString();
    if (users[i]["number"]) {
      users[i]["phone_number"] =
        users[i]["number"]["country_code"] + users[i]["number"]["phone_number"];
    } else {
      users[i]["phone_number"] = "";
    }
    delete users[i]["profile"];
    delete users[i]["number"];
  }
  return users;
}

function UserList(props) {
  let { accountId } = useParams();
  const [users, setUsers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [response, setResponse] = useState({});
  const [userModalVisible, setUsersModalVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleFeedback = (response) => {
    if (response) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  useEffect(() => {
    const fetchData = (showFeedback = true) => {
      setShowLoader(true);
      ApiService.get(`/api/v1/dashboard/retrieve-users/${accountId}/`)
        .then((res) => {
          if (res.data.success) {
            setUsers(formatTableData(res.data.data));
          }
          setShowLoader(false);
          if (showFeedback) handleFeedback(res);
        })
        .catch((err) => {
          setShowLoader(false);
          handleFeedback(err.response);
        });
    };

    if (accountId) {
      fetchData();
    } else {
      setUsers(formatTableData(props?.users));
    }
  }, [accountId]);

  const handleEvent = (params) => {
    const userId = params.id;
    setSelectedUserId(userId);
    setUsersModalVisible(true);
  };

  const handleCloseModal = () => {
    setUsersModalVisible(false);
  };

  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 60, flex: 1 },
    { field: 'first_name', headerName: 'First Name', minWidth: 150, flex: 1 },
    { field: 'last_name', headerName: 'Last Name', minWidth: 150, flex: 1 },
    { field: 'email', headerName: 'Email', minWidth: 150, flex: 1 },
    { field: 'phone_number', headerName: 'Phone number', minWidth: 150, flex: 1 },
    { field: 'date_joined', headerName: 'Date Joined', minWidth: 150, flex: 1 },
    { field: 'role', headerName: 'Role', minWidth: 150, flex: 1 },
    { field: 'is_active', headerName: 'Is Active', minWidth: 60, flex: 1 },
    { field: 'account_holder', headerName: 'Account Holder', minWidth: 150, flex: 1 },
    { field: 'licenses', headerName: 'Licenses', minWidth:320, flex: 1 }, 
  ];

  return (
    <div>
      <div className={styles.accounts_list}>
        {showLoader ? (
          <div className={styles.loader_wrapper}>
            <CircularProgress color="success" size={60} />
          </div>
        ) : (
          <CustomTable
            rows={[...users]}
            columns={columns}
            export={true}
            onCellClick={handleEvent}
            noRowsOverlayText="Users"
            pageSize={15}
            exportOption={true}
            disableCheckboxSelection={true}
            showFilters={true}
          />
        )}
      </div>
      {userModalVisible && (
        <UserDetails visible={userModalVisible.visible} userId={selectedUserId} onClose={handleCloseModal} />
      )}
      {showSnackbar && (
        <CustomSnackbar
          setShowSnackbar={setShowSnackbar}
          message={response.message}
          type={response.success ? 'success' : 'error'}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
}

export default UserList;
