import React, { useEffect, useState } from "react";
import styles from "./accounts.module.css";
import pageStyles from "../../components/ui/CommonStyles.module.css";
import ApiService from "../../components/apiService/ApiService";
import CircularProgress from "@mui/material/CircularProgress";
import { Breadcrumbs } from "@mui/material";
import { useNavigate } from "react-router";
import Snackbar from "../../components/ui/CustomSnackbar/customSnackbar";
import CustomTable from "../../components/ui/CustomTable/customTable";

function AccountsList() {
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [response, setResponse] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const handleFeedback = (response) => {
    if (response) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  const fetchData = () => {
    setShowLoader(true);
    ApiService.get("/api/v1/dashboard/retrieve-accounts/")
      .then((res) => {
        setShowLoader(false);
        handleFeedback(res);
        if (res.data.success === true) {
          setFilteredAccounts(res.data.data);
          setAccounts(res.data.data);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
        console.log("err", err);
      });
  };


  const handleRowClick = (data) => {
    navigate(`/account/${data.id}`)
  };
  const columns = [
    { field: 'id', headerName: 'ID',minWidth: 20,flex:1},
    { field: 'name', headerName: 'Name',minWidth: 150, flex:1},
    { field: 'company', headerName: 'Company',minWidth: 150,flex:1},
    { field: 'status', headerName: 'Status',minWidth: 150,flex:1},
    { field: 'primary_account', headerName: 'Primary account',minWidth: 150,flex:1},
    { field: 'created_by', headerName: 'Created by',minWidth: 150,flex:1},
    { field: 'created_at', headerName: 'Created at', minWidth: 150,flex:1},
    { field: 'use_case', headerName: 'Use Case', minWidth: 150,flex:1},
    { field: 'team_size', headerName: 'Team size',minWidth: 150, flex:1},
    { field: 'payment_due_date', headerName: 'Payment Due Date',minWidth: 150, flex:1},

  ]

  return (
    <div className={`${pageStyles.page_container} body_gap`}>
      <div className={styles.page_title}>
        <Breadcrumbs className={pageStyles.breadcrumbs} aria-label="breadcrumb">
          <p>Accounts ({filteredAccounts.length})</p>
        </Breadcrumbs>
      </div>

      {showLoader ? (
        <div className={styles.loader_wrapper}>
            <CircularProgress color="success" size={60} />
        </div>
        ) : (
          <CustomTable
            rows={filteredAccounts}
            columns={columns}
            disableCheckboxSelection={true}
            onCellClick={handleRowClick}
            noRowsOverlayText="Accounts"
            pageSize={15}
            exportOption={true}
            showFilters={true}
          />
        )
      }
      {showSnackbar && (
        <Snackbar message={response.message} onClose={() => setShowSnackbar(false)}  type={response.success ? 'success' : 'error'}
        />
      )}
    </div>
  );
}

export default AccountsList;