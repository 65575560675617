import React, { useState } from "react";
import { Button } from "@mui/material";
import Locations from "../vnLocations/locations";
import ApiService from "../apiService/ApiService";
import styles from "./addVNModal.module.css";

function AddVNForm(props) {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [AddVNLocation, setAddVNLocation] = useState(null);

  const handleAdd = () => {
    if (AddVNLocation && name && number) {
      const payload = {
        name: name,
        location: AddVNLocation.id,
        type: "Calling",
        number: number,
      };

      ApiService.post(
        `api/v1/dashboard/assign-vn/${props.accountId}/${props.userId}/`,
        payload
      )
        .then((response) => {
          props.handleFeedback(response, false);
          if (props.onAddVNSuccess) {
            props.onAddVNSuccess();
          }
        })
        .catch((error) => {
          console.log("err", error.response);
          props.handleFeedback(error.response);
        });
    }
  };

  const handleCancel = () => {
    props.onCancel();
  };
  

  return (
    <div className={styles.vnForm}>
      <label>
        Name
        <input
          style={{ border: "1px solid gray", height: "25px" }}
          type="text"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </label>

      <label>
        Virtual Number
        <input
          style={{ border: "1px solid gray", height: "25px" }}
          type="text"
          value={number}
          onChange={(e) => {
            setNumber(e.target.value);
          }}
        />
      </label>

      <label>
        Location
       <Locations setSelectedLocation={setAddVNLocation}  />
      </label>

      <div className={styles.action_btn}>
      <Button
          variant="outlined"
          style={{
            fontSize: 14,
            height: "max-content",
            marginTop: "10px",
            width: "max-content",
            borderColor: "#E1E5E2",
            color: "#464D48"
          }}
          onClick={handleCancel} 
        >
          Cancel
        </Button>
      
        <Button
          variant="contained"
          style={{
            fontSize: 14,
            height: "max-content",
            marginTop: "10px",
            width: "max-content",
            backgroundColor: "#34CB65"
          }}
          onClick={handleAdd}
        >
          Add
        </Button>

      </div>
    </div>
  );
}

export default AddVNForm;
