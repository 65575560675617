import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "./search.module.css"
import pageStyles from "../../components/ui/CommonStyles.module.css";
import UserSearch from "./components/userSearch";

const theme = createTheme({ components:{MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
            border: '1px solid #D9D9D9',
          '&.Mui-selected': {
            backgroundColor: '#F0FCF4', 
            color:'black',
          },
        },
      },
    },
  },
});

export default function Search() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={`${pageStyles.page_container} body_gap ${styles.container_body}`}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Users" />
        </Tabs>
        {value === 0 && <UserSearch />}
      </div>
    </ThemeProvider>
  );
}
