import { useState } from "react";
import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Snackbar from "../../../components/ui/CustomSnackbar/customSnackbar";
import ApiService from "../../../components/apiService/ApiService";
import UserList from "../../Accounts/AccountDetails/Users/users";
import styles from "../search.module.css";

export default function UserSearch() {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [response, setResponse] = useState({});
  const [search, setSearch] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [users, setUsers] = useState([]);

  const handleFeedback = (response) => {
    if (response) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  const handleUserSearch = (event) => {
    event.preventDefault();
    setShowLoader(true);
    if (search.length == 0) {
      setShowLoader(false)
      setResponse({
        success: false,
        message: "Invalid  address",
      });
      setShowSnackbar(true);
    } else {
      ApiService.get(`/api/v1/dashboard/retrieve-users/?search=${search}`)
        .then((res) => {
          if (res.data.success) {
            handleFeedback(res);
            setUsers(res.data.data);
            setShowDetail(true);
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <div>
      <Box component="form" sx={{ "& .MuiTextField-root": { m: 1, width: "25ch", marginLeft: 0 }, }}
        noValidate
        autoComplete="off"
        onSubmit={handleUserSearch}>
        <div style={{marginTop:'10px'}}>
          <input
            id="outlined-size-small"
            type="text"
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button
            variant="outlined" style={{   fontSize: 14,   height: "max-content",   marginLeft: "7px",   width: "max-content", }}
            sx={{ bgcolor: "#F0FCF4",border: "1px solid #E1E5E2", color: "#464D48",
           ":hover": {
                bgcolor: "lightgreen",
                color: "white",
                border: "1px solid #E1E5E2",
              },
            }}
            color="info"
            type="submit"
          >
            Search
          </Button>
        </div>
      </Box>
      
      {showLoader ? (
        <div className={styles.loader_wrapper}>
          <CircularProgress color="success" size={60} />
        </div>
      ) : (
        showDetail && <UserList users={users} />
      )}

      {showSnackbar && (
        <Snackbar
          message={response.message}
          type={response.success ? 'success' : 'error'}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
}
