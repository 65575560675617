import React, { useState } from "react";
import { Breadcrumbs, Button } from "@mui/material";
import Snackbar from "../../components/ui/CustomSnackbar/customSnackbar";
import pageStyles from "../../components/ui/CommonStyles.module.css";
import ApiService from "../../components/apiService/ApiService";
import "./test.css";

function Test() {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [response, setResponse] = useState({});
  const [fromNumber, setFromNumber] = useState("");
  const [fromPhoneNumber, setFromPhoneNumber] = useState("");
  const [candidatePhoneNumber, setCandidatePhoneNumber] = useState("");

  const handleFeedback = (response) => {
    if (response && response.data) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  const handleTestNumber = () => {
    ApiService.post(`/api/v1/dashboard/call-testing/`, {
      virtual_number: "+91" + fromNumber,
      phone_number: "+91" + fromPhoneNumber,
      candidate_number:"+91"+candidatePhoneNumber
    })
      .then((res) => {
        handleFeedback(res);
        if (res.data.success === true) {
        }
      })
      .catch((err) => {
        handleFeedback(err.response);
      });
  };

  const areInputsFilled = () => {
    return fromNumber.trim() !== "" && fromPhoneNumber.trim() !== "" && candidatePhoneNumber.trim() !== "" ;
  };

  return (
    <div className={`${pageStyles.page_container} body_gap`}>
      <div >
        <Breadcrumbs className={pageStyles.breadcrumbs} aria-label="breadcrumb">
          <p>Test</p>
        </Breadcrumbs>
      </div>

      <form>
        <div >
          <div className="property">
            <label>Virtual Number</label>
            <input
              type="text"
              name="virtual_number"
              value={fromNumber}
              onChange={(e) => setFromNumber(e.target.value)}
            />
          </div>
          <div className="property">
            <label>From Number</label>
            <input
              type="text"
              name="from_number"
              value={fromPhoneNumber}
              onChange={(e) => setFromPhoneNumber(e.target.value)}
            />
          </div>
          <div className="property">
            <label>Candidate Number</label>
            <input
              type="text"
              name="candidate_number"
              value={candidatePhoneNumber}
              onChange={(e) => setCandidatePhoneNumber(e.target.value)}
            />
          </div>

          <div>
            <Button
              disabled={!areInputsFilled()} 
              sx={{
                bgcolor: "#34cb65",
                marginTop: "10px",
                ":hover": {
                  bgcolor: "#34cb65",
                  color: "white",
                },
              }}
              variant="contained"
              onClick={handleTestNumber}
            >
              Test
            </Button>
          </div>
        </div>
      </form>

      {showSnackbar && (
        <Snackbar
          message={response.message}
          type={response.success ? "success" : "error"}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
}

export default Test;
