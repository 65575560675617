import React, { useState, useEffect } from "react";
import { Tabs, Tab, FormControl, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ApiService from "../apiService/ApiService";
import styles from "./UploadVNModal.module.css";
import Locations from "../vnLocations/locations";

const UploadVNModal = (props) => {
  const [uploadMode, setUploadMode] = useState(0);
  const [number, setNumber] = useState("");
  const [location,setLocation] = useState("");
  const [file, setFile] = useState([]);
  const [VNLocation, setVNLocation] = useState(null);


  useEffect(() => {
    ApiService.get("api/v1/auth/locations/")
      .then((response) => {
        const cities = response.data;
        setVNLocation(cities);
      })
      .catch((error) => {
        props.handleFeedback(error);

        console.error("Error fetching locations: ", error);
      });
  }, []);

  const formatFileSize = (number) => {
    if (number < 1024) {
      return `${number} bytes`;
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`;
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`;
    }
  };

  const handleAddVn = (mode) => {
    let payload = {
      
    };
    let formData = new FormData();
    const locationId = VNLocation ? VNLocation.id : null;
    if (mode === "single") {
      payload = {
        virtual_number: number,
        location: locationId
      };
    } else if (mode === "file") {
      if (file.length !== 0) {
        formData.append("file", file[0]);
        formData.append("location", locationId);
      }
    }
    console.log(VNLocation)
    ApiService.post(
      "/api/v1/dashboard/add-vns/",
      mode === "single" ? payload : formData,
    )
      .then((res) => {
        if (res?.data?.success) {
          props.handleFeedback(res);
          props.fetchData();
        } else {
          props.handleFeedback(res);
        }
      })
      .catch((err) => {
        console.log(err);
        props.handleFeedback(err.response);
      })
      .finally(() => {
        props.onClose();
        setNumber("");
        setLocation([]);
        setFile([]);
      });
  };

  return (
    <div className={styles.modalBody}>
      <Tabs
        indicatorColor="#34cb65"
        style={{
          borderBottom: "1px solid lightgray",
        }}
        value={uploadMode}
        onChange={(e, val) => setUploadMode(val)}
      >
        <Tab
          label="Add Single Number"
        />
        <Tab
          label="Upload File"
        />
      </Tabs>

      {uploadMode === 0 && (
        <form >
          <label className="label">
            Location
         <Locations setSelectedLocation={setVNLocation}/>
          </label>
          <label className="label">
            Virtual Number
            <input
              style={{ border: "1px solid #cccaca", borderRadius: 5 }}
              placeholder="Your Number"
              type="text"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />
          </label>

          <div style={{ paddingTop: 65 }}>
            <Button
              sx={{
                bgcolor: "#36cb64",
                color: "white",
                ":hover": {
                  bgcolor: "#28994B",
                },
              }}
              className={styles.button}
              variant="outlined"
              onClick={() => handleAddVn("single")}
            >
              Add
            </Button>

            <div>
              <button
                className={styles.button}
                variant="outlined"
                color="success"
                onClick={(e) => {
                  e.preventDefault();
                  setNumber("");
                  setVNLocation(null);
                  props.onClose();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}

      {uploadMode === 1 && (
        <form>
          <label className="label">
            Location
            <FormControl
              style={{ width: "100%", fontSize: "1em" }}
              size="small"
            >
              <Locations setSelectedLocation={setVNLocation} />

            </FormControl>
          </label>
          <label htmlFor="upload-list-file" className={styles.uploadFileLabel}>
            {file.length === 0 && (
              <>
                <div className={styles.noFile}>
                  <div>
                    <a className="t6 ic-green-500">
                      <span style={{ color: "#34cb65" }}>Click to upload</span>{" "}
                      or drag and drop
                    </a>
                    <p className={styles.uploadDetail} >
                      File format - csv (max file size 10MB)
                    </p>
                  </div>
                </div>
                <input
                  id="upload-list-file"
                  placeholder="or drag and drop files here"
                  name="file"
                  type="file"
                  disabled={file[0]?.name}
                  accept=".csv"
                  onChange={(e) => {
                    const _file = e.target.files[0];
                    if (_file?.type === "text/csv") {
                      setFile([e.target.files[0]]);
                    } else setFile([]);
                  }}
                />
              </>
            )}
            {file[0]?.name && (
              <div className={styles.hasFile}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    <p>
                      {file[0].name.substring(0, 35)}
                      {file[0].name.length > 35 ? "…" : ""}
                    </p>
                    <p>{formatFileSize(file[0]?.size)}</p>
                  </div>
                </div>
              </div>
            )}
          </label>
          {file[0]?.name && (
            <CloseIcon
              className={styles.closeIcon}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setFile([]);
              }}
            />
          )}
          <div>
            <Button
              sx={{
                bgcolor: "#36cb64",
                color: "white",
                ":hover": {
                  bgcolor: "#28994B",
                },
              }}
              className={styles.button}
              variant="outlined"
              color="success"
              onClick={() => handleAddVn("file")}
            >
              Upload
            </Button>
            <div>
              <button
                className={styles.button}
                variant="outlined"
                color="success"
                onClick={(e) => {
                  e.preventDefault();
                  setNumber("");
                  setVNLocation(null);
                  props.onClose();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default UploadVNModal;
