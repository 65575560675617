import styles from "./userDetails.module.css";
import { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CancelIcon from "@mui/icons-material/CloseOutlined";
import FormControl from "@mui/material/FormControl";
import { useNavigate, useLocation } from "react-router";
import ApiService from "../../../../../components/apiService/ApiService";
import Snackbar from "../../../../../components/ui/CustomSnackbar/customSnackbar";
import CustomTable from "../../../../../components/ui/CustomTable/customTable";
import ReleaseVNModal from "../../../../../components/releaseVNModal/releaseVNModal";
import CustomModal from "../../../../../components/ui/CustomModal/customModal";
import AddVNForm from "../../../../../components/addVNModal/addVNModal";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  styled,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  Grid,
} from "@mui/material";

function UserDetails({ userId, onClose }) {
  const location = useLocation();
  const [details, setDetails] = useState(null);
  const [accountId, setAccoountId] = useState(null);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateJoined, setDateJoined] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [license, setLicense] = useState([]);
  const [accountHolder, setAccountHolder] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  const [isBrowserCalls, setIsBrowserCalls] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [hubspotAccess, setHubspotAccess] = useState(null);
  const [pipedriveAccess, setPipedriveAccess] = useState(null);
  const [zohoAccess, setZohoAccess] = useState(null);
  const [mondayAccess, setMondayAccess] = useState(null);
  const [deskeraAccess, setDeskeraAccess] = useState(null);
  const [virtualNumbers, setVirtualNumbers] = useState([]);
  const [showVNModal, setShowVNModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [response, setResponse] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [showReleaseVNModal, setShowReleaseVNModal] = useState(false);
  const [releaseVns, setReleaseVns] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [selectedLicense, setSelectedLicense] = useState("");
  const [code, setCode] = useState("");
  const [codeType, setCodeType] = useState("Select type");

  const closeModal = () => {
    setModalVisible(false);
    onClose();
  };

  const roleToNumber = {
    "super admin": 1,
    admin: 4,
    user: 2,
    viewer: 3,
  };

  const navigate = useNavigate();

  const formatVirtualNumbers = (data) => {
    let inboundNumbers = data.inbound_virtual_numbers;
    let outboundNumbers = data.outbound_virtual_numbers;

    let uniqueNumbers = new Map();
    inboundNumbers.forEach((number) => {
      let numberKey = number.id;
      if (!uniqueNumbers.has(numberKey)) {
        uniqueNumbers.set(numberKey, {
          ...number,
          inbound: true,
          outbound: false,
        });
      }
    });
    console.log(license);
    outboundNumbers.forEach((number) => {
      let numberKey = number.id;
      if (!uniqueNumbers.has(numberKey)) {
        uniqueNumbers.set(numberKey, {
          ...number,
          inbound: false,
          outbound: true,
        });
      } else {
        uniqueNumbers.set(numberKey, {
          ...number,
          outbound: true,
          inbound: true,
        });
      }
    });
    let virtualNumbers = Array.from(uniqueNumbers.values());
    return virtualNumbers;
  };

  const updateDetails = (details) => {
    setUserName(`${details.first_name} ${details.last_name}`);
    setIsActive(details.is_active);
    setPhoneNumber(
      details.number
        ? details.number.country_code + details.number.phone_number
        : "",
    );
    setAccoountId(details.profile.org);
    setRole(details.profile.role);
    setStatus(details.profile.status);
    setLicense(details.licenses);
    setAccountHolder(details.profile.account_holder);
    setIsVerified(details.profile.is_verified);
    setIsBrowserCalls(details.profile.browser_calls);
    setHubspotAccess(details.profile.hubspot_access);
    setPipedriveAccess(details.profile.pipedrive_access);
    setZohoAccess(details.profile.zoho_phonebridge_access);
    setMondayAccess(details.profile.monday_access);
    setDeskeraAccess(details.profile.deskera_access);
    setEmail(details.email);
    setDateJoined(details.date_joined);
    setVirtualNumbers(formatVirtualNumbers(details));
  };

  const handleDissociate = (vnId, type) => {
    let request_endpoint = "";
    if (type === "ovn") {
      request_endpoint = `/api/v1/dashboard/dissociate-ovn/${userId}/${vnId}/`;
    } else if (type === "ivn") {
      request_endpoint = `/api/v1/dashboard/dissociate-ivn/${userId}/${vnId}/`;
    } else {
      request_endpoint = `/api/v1/dashboard/dissociate-vbvn/${userId}/${vnId}/`;
    }
    ApiService.delete(request_endpoint)
      .then((res) => {
        handleFeedback(res);
        setVirtualNumbers(formatVirtualNumbers(res.data.data));
        fetchData();
      })
      .catch((err) => {
        handleFeedback(err.response);
        console.log("err", err);
      });
  };

  const handleDelete = () => {
    const response = window.confirm(
      "Are you sure you want to delete this user?",
    );

    if (!response) return;
    ApiService.delete(`/api/v1/dashboard/delete-user/${userId}`)
      .then((res) => {
        handleFeedback(res);
        navigate(-1, { replace: true });
      })
      .catch((err) => {
        handleFeedback(err.response);
        console.log("err", err);
      });
  };

  const handleDiscardChanges = () => {
    updateDetails(details, true);
    setDisabled(true);
  };

  const handleSaveChanges = () => {
    if(!email || !email.trim()?.length) return;
    setDisabled(true);
    const roleNumber = isNaN(role)
      ? roleToNumber[role.toLowerCase().trim()]
      : role;

    const updateData = {
      profile: {
        status: status,
        role: roleNumber,
        is_verified: isVerified,
        account_holder: accountHolder,
        browser_calls: isBrowserCalls,
        release_vns: !isActive ? releaseVns : false,
      },
      first_name: userName.split(" ")?.[0] || "",
      last_name: userName.split(" ")?.[1] || "",
      number: phoneNumber,
      is_active: isActive,
      email: email
    };
    ApiService.patch(
      `/api/v1/dashboard/update-user-details/${userId}/`,
      updateData,
    )
      .then((response) => {
        if (response.data.success) {
          if (releaseVns) {
            fetchData();
            setReleaseVns(false);
          }
        }
        if (!response.data.success) {
          handleDiscardChanges();
          handleFeedback({
            data: { success: false, message: response.data.message },
          });
          return;
        }
        handleFeedback(response, true);
      })
      .catch((err) => {
        console.log("Error", err);
        handleDiscardChanges();
        handleFeedback(err.response);
      });
  };

  const handleRetrieveCode = (val) => {
    if(val === 'Select type') return;
    setCodeType(val);
    ApiService.get(`/api/v1/dashboard/retrieve-code/${userId}/?code_type=${val}`)
    .then((response)=>{
      if(!response.data.success){
        handleFeedback({
          data: { success: false, message: response.data.message },
        });
        return;
      }
      setCode(response.data?.data?.code);
      handleFeedback(response, true);
    })
    .catch((err)=>{
      console.log("Error", err);
      handleFeedback(err.response);
      setCode('');
    })
    .finally(() => {
      setTimeout(() => {
        setCodeType("Select type");
      }, 5000);
    })
  }

  const handleAddVNSuccess = () => {
    fetchData();
  };

  const handleFeedback = (response) => {
    if (response && response.data) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  const handleReleaseVN = (val) => {
    setReleaseVns(val);
  };
  const columns = [
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    { field: "name", headerName: "Name", minWidth: 150, flex: 1 },
    {
      field: "country_code",
      headerName: "Country Code",
      minWidth: 150,
      flex: 1,
    },
    { field: "number", headerName: "Number", minWidth: 150, flex: 1 },
    { field: "location", headerName: "Location", minWidth: 150, flex: 1 },
    {
      field: "access",
      headerName: "Access",
      width: 250,
      renderCell: (params) => (
        <div>
          {params.row.inbound && (
            <Chip
              label="Inbound"
              color="default"
              variant="outlined"
              sx={{ borderRadius: "5px" }}
              onDelete={() => {
                handleDissociate(params.row.id, "ivn");
              }}
              deleteIcon={<CancelIcon style={{ fontSize: "12px" }} />}
            />
          )}{" "}
          {params.row.outbound && (
            <Chip
              label="Outbound"
              color="default"
              variant="outlined"
              sx={{ borderRadius: "5px" }}
              onDelete={() => {
                handleDissociate(params.row.id, "ovn");
              }}
              deleteIcon={<CancelIcon style={{ fontSize: "12px" }} />}
            />
          )}
        </div>
      ),
    },
    {
      field: "default",
      headerName: "Default",
      width: 250,
      renderCell: (params) => (
        <div>
          {!params.row.default_calling_number && (
            <Chip
              label="Calling"
              color="default"
              variant="outlined"
              sx={{ borderRadius: "5px" }}
            />
          )}{" "}
          {!params.row.default_sms_number && (
            <Chip
              label="Sms"
              color="default"
              variant="outlined"
              sx={{ borderRadius: "5px" }}
            />
          )}
        </div>
      ),
    },
  ];

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const fetchData = () => {
    setShowLoader(true);
    ApiService.get(`/api/v1/dashboard/retrieve-user-details/${userId}/`)
      .then((res) => {
        handleFeedback(res);
        if (res.data.success === true) {
          setDetails(res.data.data);
          updateDetails(res.data.data);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleAddLicense = (licenseItem) => {
    const updateData = {
      license: licenseItem,
    };
  
    ApiService.patch(
      `/api/v1/dashboard/update-license-details/${userId}/`,
      updateData
    )
      .then((response) => {
        if (response.data.success) {
          fetchData(); 
          handleFeedback(response, true);
        }
      })
      .catch((err) => {
        handleFeedback(err.response);
      });
  };
  
  const handleRemoveLicense = (licenseItem) => {
    const updateData = {
      license: licenseItem,
      action: "remove",
    };
  
    ApiService.patch(
      `/api/v1/dashboard/update-license-details/${userId}/`,
      updateData
    )
      .then((response) => {
        if (response.data.success) {
          fetchData(); 
          handleFeedback(response);
        }
      })
      .catch((err) => {
        handleFeedback(err.response);
      });
  };
  const body = (
    <div className={styles.container}>
      {showLoader ? (
        <div className={styles.loader_wrapper}>
          <CircularProgress color="success" size={60} />
        </div>
      ) : (
        <div>
          <div className={styles.settings}>
            <div className={styles.accountSettings}>
              <label className={styles.mainheading}>Personal Information</label>
              <div className={styles.property}>
                <label>Name</label>
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => {
                    setDisabled(false);
                    setUserName(
                      typeof e.target.value === "string" ? e.target.value : "",
                    );
                  }}
                />
              </div>
              <div className={styles.property}>
                <label>Email</label>
                <input 
                  type="text" 
                  name="email" 
                  value={email} 
                  onChange={(e)=>{
                    setDisabled(false)
                    setEmail(e.target.value);
                    }}
                />
              </div>
              <div className={styles.property}>
                <label>Phone Number</label>
                <input
                  type="text"
                  name="phone_number"
                  value={phoneNumber}
                  onChange={(e) => {
                    setDisabled(false);
                    setPhoneNumber(e.target.value);
                  }}
                />
              </div>
              <div className={styles.property}>
                <label>Date Joined</label>
                <input
                  type="text"
                  name="date_joined"
                  value={dateJoined}
                  disabled
                />
              </div>
              <div className={`${styles.property}`}>
                <label>Get code</label>
                <div className={`${styles.property} ${styles.row}`}>
                  <div className={styles.code}>
                    {code}
                  </div>
                  <Select
                    sx={{flexBasis: '50%', fontSize: '14px'}}
                    size="small"
                    value={codeType}
                    onChange={(e) => handleRetrieveCode(e.target.value)}
                  >
                    <MenuItem value="Select type" disabled>Select type</MenuItem>
                    <MenuItem value="is_verification">Verification</MenuItem>
                    <MenuItem value="is_reset">Password Reset</MenuItem>
                    <MenuItem value="is_invite">User Invite</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.accountSettings}>
              <label className={styles.mainheading}>Accessbility</label>
              <div className={styles.property}>
                <FormControlLabel
                  value={isBrowserCalls}
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={isBrowserCalls}
                      onChange={(e) => {
                        setDisabled(false);
                        setIsBrowserCalls(e.target.checked);
                      }}
                    />
                  }
                  label="Browser Calls"
                  labelPlacement="end"
                />
              </div>
              <div className={styles.txt}>Ability to call from browser</div>
              <div className={styles.property}>
                <label>User Status</label>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    name="status"
                    value={status}
                    onChange={(e) => {
                      setDisabled(false);
                      setStatus(e.target.value);
                    }}
                  >
                    <MenuItem value="ONLINE">Online</MenuItem>
                    <MenuItem value="AWAY">Away</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={styles.property}>
                <label>License</label>
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: "#F0FCF4" }}
                  >
                    <Typography sx={{ color: "#34cb65" }}>
                      {" "}
                      Assign License
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <div className={styles.property}>

                  <select
                onChange={(e) => setSelectedLicense(e.target.value)} 
                style={{ width: "278px",height:'38px', marginTop:'30px' }}
              >
                <option >Select License</option>
                <option value="Plan" disabled={license.includes("Plan")}>Plan</option>
                <option value="Viewer" disabled={license.includes("Viewer")}>Viewer</option>
                <option value="Autodial" disabled={license.includes("AUTODIAL")}>Autodial</option>
                <option value="Browser Calling" disabled={license.includes("BROWSER CALLING")}>BROWSER CALLING</option>
              </select>
              <Button
                variant="outlined"
                size="medium"
                style={{
                  fontSize: 14,
                  height: "max-content",
                  marginTop: "30px",
                  color:'white',
                  width: "max-content",

                  marginLeft: "auto",
                  marginRight: "10px",
                  backgroundColor: "#34CB65",
                }}
                onClick={() => handleAddLicense(selectedLicense)} 
              >
                Assign License
              </Button>
              </div>
                  </AccordionDetails>
                </Accordion>
                <div className={styles.property}>
                  <div>
                    {license.map((licenseItem, index) => (
                      <Chip
                        key={index}
                        label={licenseItem}
                        color="default"
                        variant="outlined"
                        sx={{ borderRadius: "5px", margin: "5px", height:'26px'}}
                        onDelete={() => handleRemoveLicense(licenseItem)}
                        deleteIcon={<CancelIcon style={{ fontSize: "12px" }} />}

                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.accountSettings}>
              <label className={styles.mainheading}>Settings</label>
              <div className={styles.property}>
                <FormControlLabel
                  value={accountHolder}
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={accountHolder}
                      onChange={(e) => {
                        setDisabled(false);
                        setAccountHolder(e.target.checked);
                      }}
                    />
                  }
                  label="Account Holder"
                  labelPlacement="end"
                />
              </div>
              <div className={styles.txt}>
                Account holder will be able to create accounts
              </div>
              <div className={styles.property}>
                <FormControlLabel
                  value={isActive}
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={isActive}
                      onChange={(e) => {
                        setDisabled(false);
                        setIsActive(e.target.checked);
                        if (!e.target.checked) setShowReleaseVNModal(true);
                      }}
                    />
                  }
                  label="IsActive"
                  labelPlacement="end"
                />
                <div className={styles.txt}>User Status</div>
                <ReleaseVNModal
                  visible={showReleaseVNModal}
                  onClose={() => setShowReleaseVNModal(false)}
                  handleReleaseVN={handleReleaseVN}
                />
              </div>
              <div className={styles.property}>
                <FormControlLabel
                  value={isVerified}
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={isVerified}
                      onChange={(e) => {
                        setDisabled(false);
                        setIsVerified(e.target.checked);
                      }}
                    />
                  }
                  label="IsVerified"
                  labelPlacement="end"
                />
                <div className={styles.txt}>User verification status</div>
              </div>
              <div className={styles.property}>
                <label>Role</label>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    value={role}
                    onChange={(e) => {
                      setDisabled(false);
                      setRole(e.target.value);
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select a role
                    </MenuItem>
                    <MenuItem value={1}>Super Admin</MenuItem>
                    <MenuItem value={4}>Admin</MenuItem>
                    <MenuItem value={2}>User</MenuItem>
                    <MenuItem value={3}>Viewer</MenuItem>
                  </Select>
                </FormControl>
                <label className={styles.IntegrationHeading}>
                  Integrations
                </label>
                <div className={styles.integration}>
                  <div className={styles.main}>
                    <Chip
                      variant="outlined"
                      label="Zoho"
                      sx={{
                        m: 1,
                        borderRadius: "5px",
                        color: zohoAccess ? "#34cb65" : "default",
                        marginLeft: 0,
                      }}
                    />
                  </div>
                  <div className={styles.main}>
                    <Chip
                      variant="outlined"
                      label="Hubspot"
                      sx={{
                        m: 1,
                        borderRadius: "5px",
                        color: hubspotAccess ? "#34cb65" : "default",
                        marginLeft: 0,
                      }}
                    />
                  </div>
                  <div className={styles.main}>
                    <Chip
                      variant="outlined"
                      label="Pipedrive"
                      sx={{
                        m: 1,
                        borderRadius: "5px",
                        color: pipedriveAccess ? "#34cb65" : "default",
                        marginLeft: 0,
                      }}
                    />
                  </div>
                  <div className={styles.main}>
                    <Chip
                      variant="outlined"
                      label="Deskera"
                      sx={{
                        m: 1,
                        borderRadius: "5px",
                        color: deskeraAccess ? "#34cb65" : "default",
                        marginLeft: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.UpdateButtons}>
              <div>
                <Button
                  sx={{
                    bgcolor: "#E0544C",
                    color: "white",
                    ":hover": {
                      bgcolor: "indianred",
                      color: "white",
                    },
                  }}
                  label="Delete"
                  onClick={handleDelete}
                  onDelete={handleDelete}
                  variant="outlined"
                  color="error"
                >
                  Delete
                </Button>
              </div>
              <div>
                <Button
                  label="Discard"
                  onClick={handleDiscardChanges}
                  onDelete={handleDiscardChanges}
                  disabled={disabled}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    bgcolor: "#34cb65",
                    ":hover": {
                      bgcolor: "#34cb6",
                      color: "white",
                    },
                  }}
                  variant="contained"
                  label="Save"
                  onClick={handleSaveChanges}
                  onDelete={handleSaveChanges}
                  color="success"
                  disabled={disabled}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.tableHeader}>
            <p className={styles.tableHeader}>Virtual Numbers</p>
          </div>

          <Accordion
            expanded={showVNModal}
            onChange={(e, isExpanded) => setShowVNModal(isExpanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: "#F0FCF4" }}
            >
              <Typography sx={{ color: "#34cb65" }}>
                Add virtual number
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px" }}>
              {showVNModal && (
                <div className={styles.centeredWrapper}>
                  <AddVNForm
                    accountId={accountId}
                    userId={userId}
                    onCancel={() => setShowVNModal(false)}
                    handleFeedback={handleFeedback}
                    onAddVNSuccess={handleAddVNSuccess}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <CustomTable
            noRowsOverlayText="Virtual Numbers"
            isTextFieldDisabled={true}
            columns={columns}
            rows={virtualNumbers}
            filterable={false}
            exportable={false}
            pageSize={10}
            disableCheckboxSelection={true}
          />
        </div>
      )}
      {showSnackbar && (
        <Snackbar
          message={response.message}
          type={response.success ? "success" : "error"}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );

  const headingBtn = (
    <Button
      color="success"
      variant="outlined"
      size="medium"
      style={{
        fontSize: 14,
        height: "max-content",
        marginTop: "10px",
        width: "max-content",
        marginLeft: "auto",
        marginRight: "10px",
        backgroundColor: "#F0FCF4",
      }}
      onClick={() => {
        window.open(`/account/${accountId}`, "_blank");
      }}
    >
      Account
    </Button>
  );

  return (
    <CustomModal
      visible={modalVisible}
      closeModal={closeModal}
      heading={"User Details"}
      headingBtn={location.pathname === "/search" ? headingBtn : null}
      body={body}
    />
  );
}

export default UserDetails;
