import styles from "./virtualNumberDetails.module.css";
import { useState, useEffect } from "react";
import { CircularProgress, Select } from "@mui/material";
import Chip from "@mui/material/Chip";
import ApiService from "../../../components/apiService/ApiService";
import {
  FormControlLabel,
  Switch,
  ThemeProvider,
  FormGroup,
} from "@mui/material";
import Snackbar from "../../../components/ui/CustomSnackbar/customSnackbar";
import { styled, useTheme } from "@mui/material/styles";

function VirtualNumberDetails({ virtualNumber }) {
    const theme = useTheme();
    const [name, setName] = useState("");
    const [workingHoursMessage, setWorkingHoursMessage] = useState(null);
    const [afterHoursMessage, setAfterHoursMessage] = useState(null);
    const [inboundUser,setInboundUser]=useState("")
    const [inboundTeam,setInboundTeam]= useState('')
    const [location,setLoaction] = useState("  ")
    const [defaultSmsNumber, setDefaultSmsNumber] = useState(null);
    const [PlayAudio, setPlayAudio] = useState(null);
    const [DefaultCallingNumber, setDefaultCallingNumber] = useState(null);
    const [recordWarning, setRecordWarning] = useState(null);
    const [response, setResponse] = useState({});
    const [number,setNumber]= useState('  ')
    const [showLoader, setShowLoader] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [callDistribution,setCallDistribution] = useState("");
    const [outBoundUser, setOutBoundUser] = useState('');
    const [users,setUsers]=useState([])

    useEffect(() => {
        fetchData();
    }, [virtualNumber]);

    const fetchData = () => {
        setShowLoader(true);
        ApiService.get(`/api/v1/dashboard/retrieve-vn-details/?search=${virtualNumber}`)
        .then((res) => {
        handleFeedback(res);
        if (res.data.success === true) {
            const data = res.data; 
            setName(data.data.name);
            setNumber(data.data.number)
            setLoaction(data.data.location);
            setDefaultSmsNumber(data.data.default_calling_number);
            setPlayAudio(data.data.play_audio);
            setDefaultCallingNumber(data.data.default_calling_number);
            setRecordWarning(data.data.record_calls);
            setCallDistribution(data.data.call_distribution);
            setInboundUser(data.data.inbound_user);
            setInboundTeam(data.data.inbound_team.name);
            setShowLoader(false);
            setOutBoundUser(data.data.outbound_users)
            setWorkingHoursMessage(data.data.working_hours_message)
            setAfterHoursMessage(data.data.after_hours_message)
            setUsers(data.data.inbound_team.users)
        }
        })
        .catch((err) => {
        setShowLoader(false);
        handleFeedback(err.response);
        });
    };

    const handleFeedback = (response) => {
        if (response && response.data) {
        setResponse(response.data);
        } else if (response && response.status === 400) {
        setResponse({
            message: "Unexpected Error. Please Try Again",
            success: false,
        });
        } 
        setShowSnackbar(true);
    };

    const IOSSwitch = styled((props) => (
        <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
            backgroundColor:
                theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
            opacity: 1,
            border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
            theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
        },
        "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
        },
        "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
        },
    }));
    return (
    <>
        {virtualNumber && ( 
        <div className={styles.settings}>
            {showLoader ? (
            <div className={styles.loader_wrapper}>
                <CircularProgress color="success" size={60} />
            </div>
            ) : (
            <>
            <div className={styles.accountSettings}>
                <div className={styles.property}>
                <label>Name</label>
                <input
                disabled
                    type="text"
                    name="name"
                    value={name}

                />
                </div>

                <div className={styles.property}>
                <label>Loction</label>
                <input
                disabled
                    type="text"
                    name="location"
                    value={location}
                 
                />
                </div>
                <div className={styles.property}>
                <label>Number</label>
                <input
                disabled
                    type="text"
                    name="created_at"
                    value={number}
                />
                </div>
                <div className={styles.property}>
                <ThemeProvider theme={theme}>
                    <FormGroup>
                    <FormControlLabel
                        value={PlayAudio}
                        style={{ height: "20px" }}
                        control={
                        <IOSSwitch
                            checked={PlayAudio}
                            sx={{ m: 1 }}
                            defaultChecked
                        />
                        }
                        label="Play audio"
                        labelPlacement="end"
                    />
                    </FormGroup>
                </ThemeProvider>
                </div>
                <div className={styles.property}>
                    <label>Working hours message</label>
                    <audio controls>
                    <source type="audio/*" src={workingHoursMessage} />
                    </audio>
                </div>
                <div className={styles.property}>
                    <label>After hours message</label>
                    <audio controls>
                    <source type="audio/*" src={afterHoursMessage} />
                    </audio>
                </div>
            </div>
            <div className={styles.accountSettings}>
                <div className={styles.property}>
                <ThemeProvider theme={theme}>
                    <FormGroup>
                    <FormControlLabel
                        style={{ height: "20px" }}
                        value={DefaultCallingNumber}
                        control={
                        <IOSSwitch
                            sx={{ m: 1 }}
                            defaultChecked
                            checked={DefaultCallingNumber}

                        />
                        }
                        label="Default calling number"
                        labelPlacement="end"
                    />
                    </FormGroup>
                </ThemeProvider>
                </div>

                <div className={styles.property}>
                <ThemeProvider theme={theme}>
                    <FormGroup>
                    <FormControlLabel
                        style={{ height: "20px" }}
                        value={defaultSmsNumber}
                        control={
                        <IOSSwitch
                            sx={{ m: 1 }}
                            defaultChecked
                            checked={defaultSmsNumber}
                        />
                        }
                        label="Default sms number"
                        labelPlacement="end"
                    />
                    </FormGroup>
                </ThemeProvider>
                </div>
                <div className={styles.property}>
                <ThemeProvider theme={theme}>
                    <FormGroup>
                    <FormControlLabel
                        value={recordWarning}
                        style={{ height: "20px" }}
                        control={
                        <IOSSwitch
                            sx={{ m: 1 }}
                            defaultChecked
                            checked={recordWarning}
                        />
                        }
                        label="Record Warning"
                        labelPlacement="end"
                    />
                    </FormGroup>
                </ThemeProvider>
                </div>
                <div className={styles.property}>
                <label>Call Distribution</label>
                <input
                disabled
                    type="text"
                    name="name"
                    value={callDistribution}

                />
                </div>
                <div className={styles.property}>
                <label>Inbound Team</label>
                <input
                    disabled
                    type="text"
                    name="inbound_team"
                    value={inboundTeam}
                />
                </div>
                <div className={styles.property}>
                    <label htmlFor="users">Team Members</label>
                    <select id="users" multiple>
                        {users.map((user,index) => (
                            <option  key={index} disabled>
                                {user}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={styles.accountSettings}>
            <div className={styles.property}>
                <label>Inbound User</label>
                <input
                   disabled
                    type="text"
                    name="inbound_user"
                    value={inboundUser}
                />
                </div>
                <div className={styles.property}>
                    <label>Outbound Users</label>
                    <select id="outboundUsers" multiple>
                        {Array.isArray(outBoundUser) ? (
                            outBoundUser.map((user, index) => (
                                <option key={index} value={user} disabled>
                                    {user}
                                </option>
                            ))
                        ) : (<></>
                        )}
                    </select>
                </div>

            </div>
            </>
            )}
            {showSnackbar && (
            <Snackbar
                message={response.message}
                type={response.success ? 'success' : 'error'}
                onClose={() => setShowSnackbar(false)}
            />
            )}
        </div>
        )}
    </>);
}

export default VirtualNumberDetails;
